import photo1 from "images/photos/photo-1.jpg";
import photo2 from "images/photos/photo-2.jpg";
import photo3 from "images/photos/photo-3.jpg";
import photo4 from "images/photos/photo-4.jpg";
import photo5 from "images/photos/photo-5.jpg";
import photo6 from "images/photos/photo-6.jpg";
import photo7 from "images/photos/photo-7.jpg";
import photo8 from "images/photos/photo-8.jpg";
import photo12 from "images/photos/photo-12.jpg";

export const images = [
    {
        original: photo1,
    },
    {
        original: photo2,
    },
    {
        original: photo3,
    },
    {
        original: photo4,
    },
    {
        original: photo5,
    },
    {
        original: photo6,
    },
    {
        original: photo7,
    },
    {
        original: photo8,
    },
    {
        original: photo12,
    },
];
