import { FC, SVGProps } from "react";

export const PhoneIcon: FC<SVGProps<SVGElement>> = ({
                                                        width,
                                                        height,
                                                        strokeWidth
                                                    }) => (
    <svg width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M22.5001 17.4376V20.4376C22.5012 20.7161 22.4441 20.9917 22.3326 21.2469C22.221 21.5021 22.0574 21.7311 21.8521 21.9194C21.6469 22.1077 21.4046 22.251 21.1408 22.3403C20.877 22.4295 20.5974 22.4626 20.3201 22.4376C17.2429 22.1032 14.2871 21.0517 11.6901 19.3676C9.27388 17.8322 7.22539 15.7837 5.69006 13.3676C4.00003 10.7588 2.9483 7.78855 2.62006 4.69755C2.59507 4.42102 2.62793 4.14231 2.71656 3.87918C2.80518 3.61604 2.94763 3.37424 3.13482 3.16917C3.32202 2.96411 3.54986 2.80026 3.80385 2.68807C4.05783 2.57589 4.3324 2.51781 4.61006 2.51755H7.61006C8.09536 2.51278 8.56585 2.68463 8.93382 3.00109C9.30179 3.31754 9.54213 3.757 9.61005 4.23755C9.73668 5.19762 9.97151 6.14028 10.3101 7.04755C10.4446 7.40548 10.4737 7.79447 10.394 8.16843C10.3142 8.5424 10.1289 8.88566 9.86005 9.15755L8.59006 10.4276C10.0136 12.9311 12.0865 15.004 14.5901 16.4276L15.8601 15.1576C16.1319 14.8887 16.4752 14.7034 16.8492 14.6236C17.2231 14.5439 17.6121 14.573 17.9701 14.7076C18.8773 15.0461 19.82 15.2809 20.7801 15.4076C21.2658 15.4761 21.7095 15.7208 22.0266 16.095C22.3437 16.4693 22.5122 16.9471 22.5001 17.4376Z"
            stroke="white" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);
